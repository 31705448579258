import request from '@/utils/request'

export default {
  // 支付宝回调
  getCallback(){
    return request({
      url: '/public/ali/callback',
      method: 'get'
    })
  },
  // 支付宝回调
  postCallback(){
    return request({
      url: '/public/ali/callback',
      method: 'post'
    })
  },
  // 生成订单
  createOrder(data,key,time,md5){
    return request({
      url: '/public/createOrder',
      method: 'post',
      headers: {
        "ticket-key": key,
        timestamp: time,
        sgin: md5
      },
      data: data
    })
  },
  // 查询示例
  example(id){
    return request({
      url: '/public/example',
      method: 'post',
      data: {
        creationDocId: id
      }
    })
  },
  // 获取组稿类型
  getCreationDoc(){
    return request({
      url: '/public/getCreationDoc',
      method: 'get'
    })
  },
  // 获取资料包
  getDataPack(creationDocId){
    return request({
      url: '/public/getDataPack/' + creationDocId,
      method: 'get'
    })
  },
  // 获取下载链接
  getDownLink(orderId,key,time,md5){
    return request({
      url: '/public/getDownLink',
      method: 'post',
      headers: {
        "ticket-key": key,
        timestamp: time,
        sgin: md5
      },
      data: {id: orderId}
    })
  },
  // 获取模型
  getModelCode(){
    return request({
      url: '/public/getModelCode',
      method: 'get'
    })
  },
  // 获取附加服务
  getOtherServer(data){
    return request({
      url: '/public/getOtherServer',
      method: 'post',
      data: data
    })
  },
  // 获取科目
  getSubject(){
    return request({
      url: '/public/getSubject',
      method: 'get'
    })
  },
  // 支付
  pay(){
    return request({
      url: '/public/pay/{orderId}',
      method: 'get'
    })
  },
  // 订单查询
  queryOrder(orderNo){
    return request({
      url: '/public/queryOrder',
      method: 'post',
      data: {orderNo: orderNo}
    })
  },
  // 查询订单信息
  queryOrderDetail(orderId){
    return request({
      url: '/public/queryOrder/' + orderId,
      method: 'post'
    })
  },
  // 查询订单支付状态
  queryPayState(orderId){
    return request({
      url: '/public/queryPayState/' + orderId,
      method: 'get'
    })
  },
  // 常见问题
  question(){
    return request({
      url: '/public/question',
      method: 'post'
    })
  },
  // 修改订单
  updateOrder(data,key,time,md5){
    return request({
      url: '/public/updateOrder',
      method: 'post',
      headers: {
        "ticket-key": key,
        timestamp: time,
        sgin: md5
      },
      data: data
    })
  },
  // 获取文件上传信息
  uploadInfo(){
    return request({
      url: '/public/uploadInfo',
      method: 'get'
    })
  },
  // 获取公告
  getNotices(){
    return request({
      url: '/public/getNotices',
      method: 'get'
    })
  },
  // 获取客服信息
  getCustomerServiceInfo(){
    return request({
      url: '/public/getCustomerServiceInfo',
      method: 'get'
    })
  },
  // 获取Ticket
  getTicket(){
    return request({
      url: '/public/getTicket',
      method: 'get'
    })
  },
  // 设置投放信息
  setLaunchInfo(data,key,time,md5){
    return request({
      url: '/public/setLaunchInfo',
      headers: {
        "ticket-key": key,
        timestamp: time,
        sgin: md5
      },
      method: 'post',
      data: data
    })
  },
  // 余额支付
  postBalancePay(id,token,key,time,md5){
    return request({
      url: '/public/balance/pay',
      headers: {
        token: token,
        "ticket-key": key,
        timestamp: time,
        sgin: md5
      },
      method: 'post',
      data: {
        orderId: id
      }
    })
  },
  // 使用智慧豆抵扣
  postUserXydou(data,token,key,time,md5){
    return request({
      url: '/public/use/xydou',
      headers: {
        token: token,
        "ticket-key": key,
        timestamp: time,
        sgin: md5
      },
      method: 'post',
      data: {
        orderId: data.id,
        use: data.use
      }
    })
  },
  // 标题优化
  postOptimize(data,key,time,md5){
    return request({
      url: '/public/optimize',
      headers: {
        "ticket-key": key,
        timestamp: time,
        sgin: md5
      },
      method: 'post',
      data: data
    })
  },
  // 客户挽留
  postDetainment(id,key,time,md5){
    return request({
      url: '/public/detainment',
      headers: {
        "ticket-key": key,
        timestamp: time,
        sgin: md5
      },
      method: 'post',
      data: {
        orderId: id
      }
    })
  },
  // 客户挽留
  postCancelAndRestore(data,key,time,md5){
    return request({
      url: '/public/cancelAndRestore',
      headers: {
        "ticket-key": key,
        timestamp: time,
        sgin: md5
      },
      method: 'post',
      data: data
    })
  },
  // 获取媒体标识
  postGetLaunchTag(type){
    return request({
      url: '/public/getLaunchTag',
      method: 'post',
      data: {
        type: type
      }
    })
  }
}
