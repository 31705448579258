import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
	// mode: 'hash',
	mode: 'history',
  // base:"/new-ui",
	routes: [
    {
      path: '/',
      redirect: '/home'
    }, {
      path: '*',
      redirect: '/'
    }, {
      path: '/home',
      name: 'home',
      component: () => import('../views/home/home')
    }, {
      path: '/index',
      name: 'index',
      redirect: '/index/thesis',
      component: () => import('../views/index/index'),
      children: [{
          path: 'thesis',
          name: 'thesis',
          component: () => import('../views/thesis/thesis'),
        },{
          path: 'questionnaire',
          name: 'questionnaire',
          component: () => import('../views/questionnaire/questionnaire'),
        },{
          path: 'review',
          name: 'review',
          component: () => import('../views/review/review'),
        },{
          path: 'speech',
          name: 'speech',
          component: () => import('../views/speech/speech'),
        },{
          path: 'thought_report',
          name: 'thought_report',
          component: () => import('../views/thought_report/thought_report'),
        },{
          path: 'experience_reflection',
          name: 'experience_reflection',
          component: () => import('../views/experience_reflection/experience_reflection'),
        },{
          path: 'course_paper',
          name: 'course_paper',
          component: () => import('../views/course_paper/course_paper'),
        },{
          path: 'open_report',
          name: 'open_report',
          component: () => import('../views/open_report/open_report'),
        },{
          path: 'task_book',
          name: 'task_book',
          component: () => import('../views/task_book/task_book'),
        },{
          path: 'sci',
          name: 'sci',
          component: () => import('../views/sci/sci'),
        },{
          path: 'internship_report',
          name: 'internship_report',
          component: () => import('../views/internship_report/internship_report'),
        },{
          path: 'order',
          name: 'order',
          component: () => import('../views/order/order'),
        },{
          path: 'IntelligentPay',
          name: 'IntelligentPay',
          component: () => import('../views/pay/IntelligentPay'),
        },{
          path: 'paySuccess',
          name: 'paySuccess',
          component: () => import('../views/pay/paySuccess'),
        },{
          path: 'caseLibrary',
          name: 'caseLibrary',
          component: () => import('../views/case_library'),
        },{
          path: 'pptLibrary',
          name: 'pptLibrary',
          component: () => import('../views/ppt_library'),
        },{
          path: 'promot',
          name: 'promot',
          component: () => import('../views/user_promot/promot/promot'),
        },{
          path: 'journal_article',
          name: 'journal_article',
          component: () => import('../views/journal_article'),
        }
        // ,{
        //   path: 'promot',
        //   name: 'promot',
        //   component: () => import('../views/user_promot/promot/promot'),
        // },{
        //   path: 'income',
        //   name: 'income',
        //   component: () => import('../views/user_promot/user/income'),
        // },{
        //   path: 'member',
        //   name: 'member',
        //   component: () => import('../views/user_promot/user/member'),
        // },{
        //   path: 'setUp',
        //   name: 'setUp',
        //   component: () => import('../views/user_promot/user/setUp'),
        // },{
        //   path: 'wallet',
        //   name: 'wallet',
        //   component: () => import('../views/user_promot/user/wallet'),
        // },{
        //   path: 'commonProblem',
        //   name: 'commonProblem',
        //   component: () => import('../views/common_problem'),
        // },{
        //   path: 'customerService',
        //   name: 'customerService',
        //   component: () => import('../views/customer_service'),
        // }
      ]
    },{
      path: '/user_promot',
      name: 'user_promot',
      redirect: '/user_promot/setUp',
      component: () => import('../views/user_promot/user_promot'),
      children: [
      //   {
      //   path: 'promot',
      //   name: 'promot',
      //   component: () => import('../views/user_promot/promot/promot'),
      // },
        {
        path: 'income',
        name: 'income',
        component: () => import('../views/user_promot/user/income'),
      },{
        path: 'member',
        name: 'member',
        component: () => import('../views/user_promot/user/member'),
      },{
        path: 'setUp',
        name: 'setUp',
        component: () => import('../views/user_promot/user/setUp'),
      },{
        path: 'wallet',
        name: 'wallet',
        component: () => import('../views/user_promot/user/wallet'),
      },{
        path: 'commonProblem',
        name: 'commonProblem',
        component: () => import('../views/common_problem'),
      },{
        path: 'customerService',
        name: 'customerService',
        component: () => import('../views/customer_service'),
      }]
    }, {
      path: '/ai_revision',
      name: 'ai_revision',
      component: () => import('../views/ai_revision'),
    }
	]
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
// 全局路由导航守卫
router.beforeEach((to, from, next) => {
  if (!to.query.t) {
    next({ ...to, query: { ...to.query, t: Date.now() }})
  } else {
    next()
  }
})
router.afterEach((to, from) => {
  to.query.t = Date.now()
})

export default router
