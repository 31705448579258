import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
// import router from "@/router"
import {EventBus} from "@/utils/eventBus"
// import store from '@/store'
// import { getToken } from '@/utils/auth'

/* 环境地址 */
let url = '/api'
/*if(process.env.VUE_APP_URL === 'production'){
  url = process.env.VUE_APP_BASE_API
}*/

// 创建axios的对象
const service = axios.create({
  baseURL: url, // url = base url + request url
  timeout: 120000, // request timeout
  headers: {
    'Content-Type': 'application/json'
  }
})

// 请求拦截拦截
service.interceptors.request.use(
  config => {
    // if (store.getters.token) {
    //   config.headers['X-Token'] = getToken()
    // }
    return config
  },
  error => {
    // console.log(error) // for debug
    return Promise.reject(error)
  }
)

// 响应拦截
service.interceptors.response.use(
  response => {
    const res = response.data
    // 如果不是20000 代表就是异常
    if (res.code !== 200) {
      if (res.code === 405001) {
        // to re-login
        MessageBox.confirm('登录已过期，请重新登录', '提示',{
          confirmButtonText: '确定',
          showCancelButton: false,
          type: 'warning'
        }).then(() => {
          localStorage.removeItem('userInfo')
          localStorage.removeItem('userSetInfo')
          EventBus.$emit("userOutLogin", 'userOutLoginTrue')
        })
      } else {
        Message({
          message: res.msg || 'Error',
          type: 'error',
          duration: 5 * 1000
        })
      }
      // Message({
      //   message: res.msg || 'Error',
      //   type: 'error',
      //   duration: 5 * 1000
      // })
      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      // 响应code20000 代表正常
      return res
    }
  },
  error => {
    // console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
